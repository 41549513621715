import { Tab, Tabs } from '@mui/material';
import { type SyntheticEvent, type ReactElement } from 'react';
import 'common/Common.scss';

const CustomTabs = ({
  tabsList,
  selectedTab,
  aditionalHighlightTab = false,
  customTabChangeAction
}: {
  tabsList: string[];
  selectedTab: number;
  aditionalHighlightTab?: boolean | CSSStyleDeclaration;
  customTabChangeAction: (
    ev: SyntheticEvent<Element, Event>,
    value: number
  ) => void;
}): ReactElement => {
  const style =
    typeof aditionalHighlightTab !== 'boolean' &&
    aditionalHighlightTab !== undefined
      ? aditionalHighlightTab
      : {};

  return (
    <Tabs
      className="tabs"
      value={selectedTab}
      onChange={customTabChangeAction}
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabsList.map((tab, index) => {
        return (
          <Tab
            label={tab}
            key={`${tab}-${index}`}
            className="tabs__element"
            style={style}
          />
        );
      })}
    </Tabs>
  );
};

export default CustomTabs;
